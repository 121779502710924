<template>
	<div>
		<h1><i class="fa-regular fa-lock-keyhole"></i> Mitch Insurance Portal</h1>

		<div class="row" v-if="step == 1">
			<div class="col-12 col-lg-6 mb-3">
				<div class="card text-center">
					<div class="card-header"><h2 class="m-0 p-0"><i class="fa-regular fa-fw fa-key"></i> User Login</h2></div>

					<div class="card-body">
						<div class="form-row">
							<div class="col-12">
								<div class="alert alert-info d-block w-100" v-if="login_loading"><div class="spinner-border spinner-border-sm text-primary" role="status"></div> Please Wait...</div>
								<div class="alert alert-success d-block w-100" v-if="login_message">{{login_message}}</div>
								<div class="alert alert-danger d-block w-100" v-if="login_error">{{login_error}}</div>
							</div>
						</div>

						<form method="post" v-on:submit.prevent="login()">
							<div class="form-row mb-2">
								<input type="text" v-model="creds.username" v-bind:disabled="login_loading == 1" placeholder="Username or Email Address" title="" class="form-control" aria-label="Your username is required." required autofocus autocomplete="off" />
							</div>

							<div class="form-row mb-2">
								<input type="password" id="password" v-model="creds.password" v-bind:disabled="login_loading == 1" placeholder="Password" title="Password" class="form-control" aria-label="Your password is required." required />
							</div>

							<div class="form-row">
								<button type="submit" class="btn btn-primary d-block w-100" v-bind:disabled="login_loading == 1"><i class="fa-regular fa-fw fa-sign-in-alt" aria-hidden="true"></i> Log In</button>
							</div>
						</form>
					</div>
				</div>
			</div>

			<div class="col-12 col-lg-6 mb-3">
				<div class="card text-center">
					<div class="card-header"><h2 class="m-0 p-0"><i class="fa-regular fa-fw fa-question-circle"></i> Forgot Your Password?</h2></div>

					<div class="card-body">
						<div class="form-row">
							<div class="col-12">
								<div class="alert alert-info d-block w-100" v-if="reset_loading"><div class="spinner-border spinner-border-sm text-primary" role="status"></div> Please Wait...</div>
								<div class="alert alert-success d-block w-100" v-if="reset_message">{{reset_message}}</div>
								<div class="alert alert-danger d-block w-100" v-if="reset_error">{{reset_error}}</div>
							</div>
						</div>

						<form method="post" v-on:submit.prevent="passwordResetEmail" v-show="reset_step == 1">
							<div class="form-row mb-2">
								<input type="email" v-model="reset.email" v-bind:disabled="reset_step == 1 && reset_loading == 1" placeholder="Email Address" title="Email Address" class="form-control" aria-label="Your email address can be used to reset your password." required autocomplete="off" />
							</div>

							<div class="form-row mb-2">
								<input type="text" class="form-control" style="visibility: hidden" />
							</div>

							<div class="form-row">
								<button type="submit" class="btn btn-info d-block w-100" v-bind:disabled="reset_loading == 1">Continue <i class="fa-regular fa-fw fa-arrow-right" aria-hidden="true"></i></button>
							</div>
						</form>

						<form method="post" v-on:submit.prevent="passwordReset" v-show="reset_step == 2">
							<div class="form-row mb-2">
								<input type="email" v-model="reset.email" v-bind:disabled="reset_loading == 1" placeholder="Email Address" title="Email Address" class="form-control" aria-label="Your email address can be used to reset your password." required autocomplete="off" />
							</div>

							<div class="form-row mb-2">
								<input type="text" id="password_reset_code" v-model="reset.code" v-bind:disabled="reset_loading == 1" placeholder="Password Reset Code" title="Password Reset Code" class="form-control" aria-label="You were emailed a password reset code." required autofocus autocomplete="off" />
							</div>

							<div class="form-row mb-2">
								<input type="password" v-model="reset.password" v-bind:disabled="reset_loading == 1" placeholder="New Password" title="New Password" class="form-control" aria-label="Enter your new password." required autocomplete="off" minlength="8" />
							</div>

							<div class="form-row">
								<button type="submit" class="btn btn-success d-block w-100" v-bind:disabled="reset_step == 2 && reset_loading == 1"><i class="fa-regular fa-fw fa-magic" aria-hidden="true"></i> Reset Password</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div v-else-if="step == 2">
			<form @submit.prevent="totpVerify()">
				<div class="card" :class="colours">
					<div class="card-header">
						<h3 class="text-center">Two Factor Authentication</h3>
					</div>

					<div class="card-body">
						<div class="form-row">
							<div class="col-12">
								<div class="alert alert-info d-block w-100" v-if="login_loading"><div class="spinner-border spinner-border-sm text-primary" role="status"></div> Please Wait...</div>
								<div class="alert alert-success d-block w-100" v-if="login_message">{{login_message}}</div>
								<div class="alert alert-danger d-block w-100" v-if="login_error">{{login_error}}</div>
							</div>
						</div>

						<div class="form-row mb-3" v-if="qrcode != null">
							<div class="col-12">
								<div class="alert alert-info mb-3">
									<i class="fa fa-info-circle"></i> Your account level requires two factor authentication, which hasn't been configured yet.
									Goliath uses what's known as a Time-based One Time Password (TOTP) which requires an accompanying smart phone app.
									We recommend <a href="https://authy.com/" target="_blank">Twilio Authy</a> or <a href="https://safety.google/authentication/" target="_blank">Google Authenticator</a>, but any TOTP app will work if you already use one for other sites.
									<hr>


								<div class="d-flex justify-content-between">
									<div class="w-50 text-center"><a href="https://authy.com/" target="_blank"><b>Twilio Authy</b></a></div>
									<div class="w-50 text-center"><a href="https://safety.google/authentication/" target="_blank"><b>Google Authenticator</b></a></div>
								</div>

								<div class="d-flex justify-content-between">
									<div class="w-50 text-center">
										<a href="https://play.google.com/store/apps/details?id=com.authy.authy" target="_blank"><img src="https://authy.com/wp-content/themes/authy/assets/images/logos/google-play.svg" /></a>
										<a href="https://itunes.apple.com/us/app/authy/id494168017" target="_blank"><img src="https://authy.com/wp-content/themes/authy/assets/images/logos/app-store.svg" /></a>
									</div>
									<div class="w-50 text-center">
										<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_CA&gl=US" target="_blank"><img src="https://authy.com/wp-content/themes/authy/assets/images/logos/google-play.svg" /></a>
										<a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank"><img src="https://authy.com/wp-content/themes/authy/assets/images/logos/app-store.svg" /></a>
									</div>
								</div>
								<hr>
									Scan this QR code or type "{{secret}}" into your TOTP app of choice on your smart phone or tablet.
									This initial step only needs to be done once.
									<hr>
									Then enter the 6-digit number provided by the app into the box below.
								</div>

								<div class="text-center">
									<div v-html="qrcode"></div>
								</div>
							</div>
						</div>
						<div class="form-row mb-3">
							<div class="col-12">
								<div class="text-center">
									<div class="alert alert-info mb-3" v-show="qrcode == null"><i class="fa fa-info-circle"></i> This is a 6-digit number that you'll get from your TOTP app of choice on your smart phone or tablet.</div>
									<input type="text" id="totp" class="form-control d-block w-100 text-center" v-model="creds.totp" placeholder="Time-based One Time Password (TOTP)" title="Time-based One Time Password (TOTP)" maxlength="6" required autofocus autocomplete="off" />
								</div>
							</div>
						</div>
					</div>

					<div class="card-footer">
						<div class="form-row">
							<div class="col-6">
								<div class="text-center">
									<button type="button" @click.prevent="qrcode=null;secret=null;step=1" class="btn btn-light d-block w-100" v-if="qrcode != null">Back</button>
									<button type="button" @click.prevent="totpToken()" class="btn btn-light d-block w-100" v-if="qrcode == null">Setup 2FA</button>
								</div>
							</div>
							<div class="col-6">
								<div class="text-center">
									<button type="submit" class="btn btn-primary d-block w-100">Login</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import jQuery from 'jquery';

export default {
	name: 'Login',
	data() {
		return {
			creds: {username: "", password: "", remember: false, totp: ""},
			reset: {email: "", code: "", password: ""},
			login_loading: false,
			login_message: "",
			login_error: "",
			step: 1,
			reset_loading: false,
			reset_message: "",
			reset_error: "",
			reset_step: 1,
			qrcode: null,
			secret: null,
			totp: ""
		}
	},
	methods:
	{
		autofocus()
		{
			this.$nextTick(() => {
				jQuery("[autofocus]").focus();
			});
		},
		login()
		{
			this.qrcode = null;
			this.secret = null;
			this.login_message = "";
			this.login_error = "";
			this.login_loading = true;
			this.creds.totp = "";
			this.$store.commit("startLoading");
			localStorage.clear();

			this.CORS("POST", "/login", JSON.stringify(this.creds),
				(data) =>
				{
					this.login_loading = false;
					this.$store.commit("stopLoading");

					// When 2FA is not required, we'll have a user ID and a user.
					if(data.success == 1 && data.user_id != null && data.user != null)
					{
						this.$emit("login");
						this.$store.commit("user", data.user);
						this.$router.push("/").catch(function() { /* ignore error */ });
					}
					// When 2FA is required, we'll have a user ID but not a user.
					else
					{
						this.step = 2;

						// If TOTP hasn't been configured yet,
						// automatically start the process.
						if(!data.totp_complete)
						{
							this.totpToken();
						}
						else
						{
							this.autofocus();
						}
					}
				},
				(data) =>
				{
					this.login_loading = false;
					this.$store.commit("stopLoading");

					if(typeof data.responseJSON == "undefined")
					{
						this.login_error = data;
					}
					else
					{
						this.login_error = data.responseJSON.message;
					}
				});
		},
		passwordResetEmail()
		{
			this.reset_message = "";
			this.reset_error = "";
			this.reset_loading = true;
			this.$store.commit("startLoading");

			this.CORS("POST", "/passwordReset", this.reset,
				(data) =>
				{
					this.reset_loading = false;
					this.$store.commit("stopLoading");

					if(data.success == 1)
					{
						this.reset_message = data.message;
						this.reset_step = 2;
						this.autofocus();
						setTimeout(function()
						{
							document.getElementById("password_reset_code").focus();
						}, 500);
					}
					else
					{
						this.reset_error = "Password Reset Failed";
					}
				},
				(data) =>
				{
					this.reset_loading = false;
					this.$store.commit("stopLoading");

					if(typeof data.responseJSON == "undefined")
					{
						this.reset_error = data;
					}
					else
					{
						this.reset_error = data.responseJSON.message;
					}
				});
		},
		passwordReset()
		{
			this.reset_message = "";
			this.reset_error = "";
			this.reset_loading = true;
			this.$store.commit("startLoading");

			this.CORS("PUT", "/passwordReset", this.reset,
				(data) =>
				{
					this.reset_loading = false;
					this.$store.commit("stopLoading");

					if(data.success == 1)
					{
						this.reset_message = data.message;
						this.reset_step = 3;
						this.creds.email = data.email;
						this.creds.password = this.reset.password;

						setTimeout(function()
						{
							document.getElementById("password").focus();
						}, 500);
					}
					else
					{
						this.reset_error = "Password Reset Failed";
					}
				},
				(data) =>
				{
					this.reset_loading = false;
					this.$store.commit("stopLoading");

					if(typeof data.responseJSON == "undefined")
					{
						this.reset_error = data;
					}
					else
					{
						this.reset_error = data.responseJSON.message;
					}
				});
		},
		totpToken: function()
		{
			this.qrcode = null;
			this.secret = null;
			this.login_message = null;
			this.login_error = null;
			this.login_loading = true;

			this.CORS("POST", "/totp/secret", JSON.stringify({origin: "goliathlms.com", username: this.creds.username}),
				(data) =>
				{
					this.login_loading = false;
					this.qrcode = data.qrcode;
					this.secret = data.secret;
					this.autofocus();
				},
				(data) =>
				{
					this.login_loading = false;
					this.login_error = data.responseJSON.error;
				});
		},
		totpVerify: function()
		{
			this.login_message = null;
			this.login_error = null;
			this.login_loading = true;

			this.CORS("POST", "/totp/verify", JSON.stringify({origin: "goliathlms.com", username: this.creds.username, secret: this.creds.totp, remember: this.creds.remember}),
				(data) =>
				{
					this.login_loading = false;

					if(data.success == 1 && data.user_id != null && data.user != null)
					{
						this.$emit("login");
						this.$store.commit("user", data.user);
						this.$router.push("/").catch(function() { /* ignore error */ });
					}
				},
				(data) =>
				{
					this.login_loading = false;
					this.creds.totp = "";
					this.login_error = data.responseJSON.error;
				});
		}
	}
}
</script>
